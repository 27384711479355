import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";
import julia from "../img/julia.jpg";
import ear from "../img/listen.svg";
import split from "../img/split.svg";
import flash from "../img/flash.svg";
import heart from "../img/heart.svg";

//redux logic
import { useDispatch } from "react-redux";
import { loadLetterArray } from "../actions/wordsAction";

const Home = () => {
  //init dispatch
  const dispatch = useDispatch();
  //fetch letters & fill state
  useEffect(() => {
    dispatch(loadLetterArray());
  }, [dispatch]);

  //init history
  const history = useHistory();
  const clickHandler = () => {
    history.push("/lautehoeren");
  };
  const zahlzerlegungHandler = () => {
    history.push("/zahlzerlegung");
  };
  const blitzlesenHandler = () => {
    history.push("/blitzlesen");
  };
  const verliebteZahlenHandler = () => {
    history.push("/verliebte-zahlen");
  };
  const blitzrechnenHandler = () => {
    history.push("/blitzrechnen");
  };

  return (
    <>
      <HomeDiv>
        <img src={julia} alt='julia' id='mainpicture' />
        <div className='mainText'>
          <h2>Hallo liebe Kinder,</h2>
          <p>hier findet ihr Aufgaben zum Üben.</p>
          <br />
          <p className='task'>Hören von Lauten in Wörten:</p>
          <ClickBox onClick={clickHandler}>
            <p id='textSymbol'>Aa</p>
            <img src={ear} alt='ear' />
          </ClickBox>
          <br />
          <p className='task'>Blitzlesen von Wörten und Silben:</p>
          <ClickBox onClick={blitzlesenHandler}>
            <p id='textSymbol'>Blitz</p>
            <img src={flash} alt='flash' />
          </ClickBox>
          <br />
          <p className='task'>Zerlegen von Zahlen:</p>
          <ClickBox onClick={zahlzerlegungHandler}>
            <p id='textSymbol'>5|3</p>
            <img src={split} alt='split' />
          </ClickBox>
          <br />
          <p className='task'>Verliebte Zahlen finden:</p>
          <ClickBox onClick={verliebteZahlenHandler}>
            <p id='textSymbol'>verliebt</p>
            <img src={heart} alt='heart' />
          </ClickBox>
          <br />
          <p className='task'>Blitzrechnen bis 10 oder 20:</p>
          <ClickBox onClick={blitzrechnenHandler}>
            <p id='textSymbol'>1 + 1</p>
            <img src={flash} alt='flash' />
          </ClickBox>
        </div>
      </HomeDiv>
      <Impressum>
        <p>Julia Löffler / 2025 / </p>
        <a
          href='https://impressum.loefflermartin.de'
          target='_blank'
          rel='noreferrer'
        >
          Impressum
        </a>{" "}
        <p>/ </p>
        <Link to='/admin'>Adminbereich</Link>
      </Impressum>
    </>
  );
};

const HomeDiv = styled(motion.div)`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "picture mainText" "impressum impressum";
  @media (max-width: 880px) {
    grid-template-columns: 1fr;
    grid-template-areas: "mainText" "picture" "impressum";
  }

  #mainpicture {
    grid-area: picture;
    justify-self: end;
    align-self: start;
    margin-top: 6.5rem;
    height: 65vh;
    margin-right: 2rem;
    border: none;
    border-radius: 2rem;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    @media (max-width: 880px) {
      justify-self: center;
      margin-top: 2rem;
      margin-right: 0;
    }
  }
  .mainText {
    width: 25vw;
    grid-area: mainText;
    justify-self: start;
    align-self: start;
    text-align: left;
    margin-top: 6.5rem;
    @media (max-width: 1200px) {
      width: 100%;
      padding-right: 5rem;
    }
    @media (max-width: 880px) {
      justify-self: center;
      text-align: center;
      margin: 2rem;
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    p {
      padding: 0;
      margin-top: 0.7rem;
      font-size: 1.3rem;
    }
  }
`;

const ClickBox = styled(motion.div)`
  cursor: pointer;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  border-radius: 1rem;
  background: #8dd693;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  @media (max-width: 880px) {
    width: 55%;
    justify-self: center;
  }
  img {
    height: 3rem;
    margin-left: 1rem;
  }
  #textSymbol {
    font-size: 3rem;
    letter-spacing: 0.5rem;
  }
`;

const Impressum = styled(motion.div)`
  grid-area: mainText;
  text-align: center;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
  }
`;

export default Home;
