import React from "react";
//global style
import GlobalStyle from "./components/GlobalStyles";

//pages & components
import Home from "./pages/Home";
import Nav from "./components/Nav";
import Lautehoeren from "./pages/Lautehoeren";
import LautehoerenUeben from "./pages/LautehoerenUeben";
import LautehoerenWoerter from "./pages/LautehoerenWoerter";
import Admin from "./pages/Admin";
import Zahlzerlegung from "./pages/Zahlzerlegung";
import Blitzlesen from "./pages/Blitzlesen";
import VerliebteZahlen from "./pages/VerliebteZahlen";
import Blitzrechnen from "./pages/Blitzrechnen";

//react router
import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className='App'>
      <GlobalStyle />
      <Nav />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/lautehoeren' component={Lautehoeren} />
        <Route exact path='/lautehoerenueben' component={LautehoerenUeben} />
        <Route
          exact
          path='/lautehoerenwoerter'
          component={LautehoerenWoerter}
        />
        <Route exact path='/admin' component={Admin} />
        <Route exact path='/zahlzerlegung' component={Zahlzerlegung} />
        <Route exact path='/blitzlesen' component={Blitzlesen} />
        <Route exact path='/verliebte-zahlen' component={VerliebteZahlen} />
        <Route exact path='/blitzrechnen' component={Blitzrechnen} />
        <Route component={Home} />
      </Switch>
    </div>
  );
}

export default App;
