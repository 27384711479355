import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const VerliebteZahlen = () => {
  const [currentNumber, setCurrentNumber] = useState(0);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [isLeftSide, setIsLeftSide] = useState(true);
  const [isCorrect, setIsCorrect] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  // Generate new number and position
  const generateNewNumber = () => {
    setCurrentNumber(Math.floor(Math.random() * 11));
    setIsLeftSide(Math.random() > 0.5);
    setSelectedNumber(null);
    setIsCorrect(null);
  };

  // Initialize game
  useEffect(() => {
    generateNewNumber();
  }, []);

  // Handle number selection
  const handleNumberSelect = (num) => {
    if (isAnimating) return;

    setSelectedNumber(num);
    const sum = currentNumber + num;
    const correct = sum === 10;
    setIsCorrect(correct);
    setIsAnimating(true);

    // Reset animation after 3 seconds but keep the selected number visible
    setTimeout(() => {
      setIsAnimating(false);
      if (correct) {
        generateNewNumber();
      }
      // Don't reset selectedNumber if incorrect, keep showing it
    }, 3000);
  };

  return (
    <StyledGame>
      <GameContainer>
        <HeartContainer
          isAnimating={isAnimating}
          isCorrect={isCorrect}
          animate={isAnimating ? (isCorrect ? "bump" : "shake") : "idle"}
          variants={{
            idle: { scale: 1, x: 0 },
            bump: {
              scale: [1, 1.2, 1, 1.2, 1, 1.2, 1],
              transition: {
                duration: 3,
                times: [0, 0.16, 0.33, 0.5, 0.66, 0.83, 1],
              },
            },
            shake: {
              x: [0, -10, 10, -10, 10, -10, 10, -10, 10, 0],
              transition: {
                duration: 3,
                ease: "linear",
                times: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1],
              },
            },
          }}
        >
          <Heart isCorrect={isCorrect}>
            <NumberLeft visible={true} isSelected={!isLeftSide}>
              {isLeftSide ? currentNumber : selectedNumber}
            </NumberLeft>
            <NumberRight visible={true} isSelected={isLeftSide}>
              {!isLeftSide ? currentNumber : selectedNumber}
            </NumberRight>
          </Heart>
        </HeartContainer>

        <ButtonContainer>
          {Array.from({ length: 11 }, (_, i) => (
            <NumberButton
              key={i}
              onClick={() => handleNumberSelect(i)}
              disabled={isAnimating}
            >
              {i}
            </NumberButton>
          ))}
        </ButtonContainer>
      </GameContainer>
    </StyledGame>
  );
};

const StyledGame = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: white;
`;

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  max-width: 1200px;
`;

const HeartContainer = styled(motion.div)`
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: ${(props) =>
    props.isCorrect === false ? "brightness(0.8)" : "none"};
  margin: 0.5rem;
  position: relative;
`;

const Heart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${(props) => (props.isCorrect === true ? "#ff6b6b" : "#ff8080")};
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: path(
    "M200,333.333 C200,333.333 0,200 0,100 C0,0 100,0 200,100 C300,0 400,0 400,100 C400,200 200,333.333 200,333.333 Z"
  );

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: white;
    transform: translate(-50%, -50%);
    clip-path: path("M200,100 L215,150 L200,333.333 L185,150 Z");
    opacity: 0.2;
  }
`;

const NumberStyle = styled.div`
  position: absolute;
  font-size: 100px;
  font-weight: bold;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease;
  color: ${(props) => (props.isSelected ? "#888888" : "black")};
  z-index: 2;
`;

const NumberLeft = styled(NumberStyle)`
  left: 30%;
  top: 45%;
  transform: translate(-50%, -50%);
`;

const NumberRight = styled(NumberStyle)`
  right: 30%;
  top: 45%;
  transform: translate(50%, -50%);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  justify-content: center;
  padding: 1.5rem;
  width: 80vw;
  max-width: 1000px;
  margin: 0 auto;
`;

const NumberButton = styled.button`
  width: 3rem;
  height: 3rem;
  border: 2px solid #ff6b6b;
  border-radius: 50%;
  background: #fff5f5;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #333;

  &:hover {
    transform: scale(1.1);
    background: #ffebeb;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
`;

export default VerliebteZahlen;
