import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Zahlzerlegung = () => {
  return (
    <StyledFrame>
      <iframe
        src='https://zahlzerlegung.loefflerjulia.de'
        title='Zahlzerlegung'
        frameBorder='0'
      />
    </StyledFrame>
  );
};

const StyledFrame = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 5vh); /* Subtract nav height */

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export default Zahlzerlegung;
