import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

const Blitzlesen = () => {
  // States for settings
  const [wordCount, setWordCount] = useState(10);
  const [shortWordsOnly, setShortWordsOnly] = useState(false);
  const [displayTime, setDisplayTime] = useState(3);
  const [selectedWords, setSelectedWords] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [allWords, setAllWords] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isCustomMode, setIsCustomMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [customWords, setCustomWords] = useState("");

  // Load words from CSV and custom words from localStorage
  useEffect(() => {
    fetch("/german_words.csv")
      .then((response) => response.text())
      .then((data) => {
        const words = data
          .split("\n")
          .map((line) => line.trim())
          .filter((word) => word);
        setAllWords(words);
      });

    const savedCustomWords = localStorage.getItem("blitzlesen_custom_words");
    if (savedCustomWords) {
      setCustomWords(savedCustomWords);
    }
  }, []);

  // Select random words based on settings
  const selectRandomWords = () => {
    let availableWords;
    if (isCustomMode) {
      // When using custom words, use all of them in random order
      availableWords = customWords.split("\n").filter((word) => word.trim());
      return availableWords.sort(() => 0.5 - Math.random());
    } else {
      // For default word list, use the selected word count
      availableWords = [...allWords];
      if (shortWordsOnly) {
        availableWords = availableWords.filter((word) => word.length <= 3);
      }
      const shuffled = availableWords.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, wordCount);
    }
  };

  // Handle custom words mode
  const handleCustomMode = () => {
    setIsCustomMode(true);
    setShowModal(true);
    setShortWordsOnly(false);
  };

  // Handle modal close
  const handleModalClose = () => {
    localStorage.setItem("blitzlesen_custom_words", customWords);
    setShowModal(false);
  };

  // Handle word mode selection
  const handleWordModeSelect = (mode) => {
    if (mode === "custom") {
      handleCustomMode();
    } else if (mode === "default") {
      setIsCustomMode(false);
    } else if (!isCustomMode) {
      // Only update word count if we're in default mode
      setWordCount(mode);
    }
  };

  // Start the word display
  const startDisplay = () => {
    const words = selectRandomWords();
    setSelectedWords(words);
    setCurrentWordIndex(0);
    setIsPlaying(true);
    setProgress(0);
  };

  // Stop the game
  const stopGame = () => {
    setIsPlaying(false);
    setProgress(0);
    setCurrentWordIndex(0);
    clearInterval(timer);
    clearInterval(progressTimer);
  };

  // Effect for word cycling and progress bar
  let timer;
  let progressTimer;
  useEffect(() => {
    if (isPlaying && selectedWords.length > 0) {
      // Word cycling timer
      timer = setInterval(() => {
        setCurrentWordIndex((prev) => {
          if (prev >= selectedWords.length - 1) {
            setIsPlaying(false);
            return prev;
          }
          setProgress(0);
          return prev + 1;
        });
      }, displayTime * 1000);

      // Progress bar timer
      const progressInterval = 50; // Update every 50ms for smooth animation
      progressTimer = setInterval(() => {
        setProgress((prev) => {
          const newProgress = prev + progressInterval / (displayTime * 1000);
          return Math.min(newProgress, 1);
        });
      }, progressInterval);
    }

    return () => {
      clearInterval(timer);
      clearInterval(progressTimer);
    };
  }, [isPlaying, selectedWords, displayTime]);

  return (
    <StyledBlitzlesen>
      <AnimatePresence>
        {showModal && (
          <Modal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ModalContent
              initial={{ y: -50 }}
              animate={{ y: 0 }}
              exit={{ y: -50 }}
            >
              <h3>Eigene Wörter</h3>
              <p>Bitte ein Wort pro Zeile eingeben</p>
              <CustomWordsTextarea
                value={customWords}
                onChange={(e) => setCustomWords(e.target.value)}
                placeholder='Wort 1&#10;Wort 2&#10;Wort 3'
                rows={10}
              />
              <OkButton onClick={handleModalClose}>OK</OkButton>
            </ModalContent>
          </Modal>
        )}
      </AnimatePresence>

      {!isPlaying ? (
        <SettingsContainer>
          <h2>Blitzlesen</h2>
          <WordSourceContainer>
            <WordSourceButton
              selected={!isCustomMode}
              onClick={() => handleWordModeSelect("default")}
            >
              Wortliste
            </WordSourceButton>
            <WordSourceButton
              selected={isCustomMode}
              onClick={() => handleWordModeSelect("custom")}
            >
              Eigene Wörter
            </WordSourceButton>
          </WordSourceContainer>

          <WordCountContainer>
            <WordCountButton
              selected={!isCustomMode && wordCount === 10}
              onClick={() => handleWordModeSelect(10)}
              disabled={isCustomMode}
              style={{ opacity: isCustomMode ? 0.5 : 1 }}
            >
              10 Wörter
            </WordCountButton>
            <WordCountButton
              selected={!isCustomMode && wordCount === 20}
              onClick={() => handleWordModeSelect(20)}
              disabled={isCustomMode}
              style={{ opacity: isCustomMode ? 0.5 : 1 }}
            >
              20 Wörter
            </WordCountButton>
          </WordCountContainer>

          <ToggleContainer>
            <label>
              <Toggle
                checked={shortWordsOnly}
                onChange={() =>
                  !isCustomMode && setShortWordsOnly(!shortWordsOnly)
                }
                disabled={isCustomMode}
              />
              <span style={{ opacity: isCustomMode ? 0.5 : 1 }}>
                Nur kurze Wörter
              </span>
            </label>
          </ToggleContainer>

          <SliderContainer>
            <label>
              <span>Anzeigezeit: {displayTime} Sekunden</span>
              <input
                type='range'
                min='1'
                max='10'
                value={displayTime}
                onChange={(e) => setDisplayTime(Number(e.target.value))}
              />
            </label>
          </SliderContainer>

          <StartButton onClick={startDisplay}>Start</StartButton>
        </SettingsContainer>
      ) : (
        <GameContainer>
          <TopBar>
            <WordCounter>
              {currentWordIndex + 1}/{selectedWords.length}
            </WordCounter>
            <CloseButton onClick={stopGame}>✕</CloseButton>
          </TopBar>
          <WordDisplay>{selectedWords[currentWordIndex]}</WordDisplay>
          <ProgressBarContainer>
            <ProgressBar progress={progress} />
          </ProgressBarContainer>
        </GameContainer>
      )}
    </StyledBlitzlesen>
  );
};

const StyledBlitzlesen = styled(motion.div)`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const GameContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: white;
`;

const TopBar = styled.div`
  position: fixed;
  top: 3.5rem;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem;
  gap: 1rem;
  z-index: 100;
  pointer-events: all;
`;

const WordCounter = styled.div`
  font-size: 2rem;
  color: #666;
  user-select: none;
`;

const CloseButton = styled.button`
  position: relative;
  background: none;
  border: none;
  font-size: 2rem;
  color: #666;
  padding: 0.8rem;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 100;
  border-radius: 50%;
  cursor: pointer !important;
  pointer-events: all;
  background: rgba(255, 255, 255, 0.9);

  &:hover {
    color: #333;
    transform: scale(1.1);
    background: rgba(0, 0, 0, 0.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  background: #f0f0f0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.progress * 100}%;
  height: 100%;
  background: #8dd693;
  transition: width 0.05s linear;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 600px;

  h2 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
`;

const WordCountContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const WordCountButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: 2px solid #8dd693;
  border-radius: 0.5rem;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: ${(props) => (props.selected ? "3px solid #8dd693" : "none")};

  &:hover {
    background: #f0f0f0;
  }
`;

const ToggleContainer = styled.div`
  label {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
  }
`;

const Toggle = styled.input.attrs({ type: "checkbox" })`
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
`;

const SliderContainer = styled.div`
  width: 100%;
  max-width: 400px;

  label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 1.2rem;
  }

  input[type="range"] {
    width: 100%;
    height: 8px;
    -webkit-appearance: none;
    background: #f0f0f0;
    border-radius: 4px;
    outline: none;
    margin: 10px 0;
    position: relative;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: #8dd693;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-top: -6px; /* Center the thumb: (20px - 8px) / 2 = 6px */

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px rgba(141, 214, 147, 0.3);
      }
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: #8dd693;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      transform: translateY(-6px); /* Center the thumb */

      &:hover {
        transform: scale(1.1) translateY(-6px);
        box-shadow: 0 0 10px rgba(141, 214, 147, 0.3);
      }
    }

    &::-ms-thumb {
      width: 20px;
      height: 20px;
      background: #8dd693;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-top: -6px; /* Center the thumb */

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px rgba(141, 214, 147, 0.3);
      }
    }

    &::-webkit-slider-runnable-track {
      background: #8dd693;
      height: 8px;
      border-radius: 4px;
    }

    &::-moz-range-track {
      background: #8dd693;
      height: 8px;
      border-radius: 4px;
    }

    &::-ms-track {
      background: #8dd693;
      height: 8px;
      border-radius: 4px;
    }
  }
`;

const StartButton = styled.button`
  padding: 1rem 3rem;
  font-size: 1.5rem;
  background: #8dd693;
  border: none;
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const WordDisplay = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${(props) => {
    const length = props.children?.length || 1;
    if (length <= 3) return "60vh";
    if (length <= 6) return "45vh";
    if (length <= 9) return "35vh";
    return "25vh";
  }};
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
  z-index: 10;
  user-select: none;
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
    margin-bottom: 1rem;
  }
`;

const CustomWordsTextarea = styled.textarea`
  width: 100%;
  min-height: 200px;
  padding: 1rem;
  border: 2px solid #8dd693;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  line-height: 1.5;
  resize: vertical;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(141, 214, 147, 0.3);
  }
`;

const OkButton = styled.button`
  padding: 1rem 3rem;
  font-size: 1.5rem;
  background: #8dd693;
  border: none;
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: center;
  margin-top: 1rem;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const WordSourceContainer = styled(WordCountContainer)`
  margin-bottom: 1rem;
`;

const WordSourceButton = styled(WordCountButton)`
  min-width: 150px;
`;

export default Blitzlesen;
