import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Blitzrechnen = () => {
  // States for settings
  const [calculationCount, setCalculationCount] = useState(10);
  const [numberRange, setNumberRange] = useState(10);
  const [operator, setOperator] = useState("+");
  const [withAnswers, setWithAnswers] = useState(false);
  const [displayTime, setDisplayTime] = useState(3);
  const [isPlaying, setIsPlaying] = useState(false);

  // Game states
  const [calculations, setCalculations] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  // Additional states for answer handling
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  // Add new states for score tracking
  const [gameResults, setGameResults] = useState(null);
  const [showScore, setShowScore] = useState(false);

  // Add a new state to track all answers
  const [answers, setAnswers] = useState([]);

  // Generate a random calculation
  const generateCalculation = () => {
    let num1, num2, op;

    if (operator === "+ & -") {
      op = Math.random() < 0.5 ? "+" : "-";
    } else {
      op = operator;
    }

    if (op === "+") {
      do {
        num1 = Math.floor(Math.random() * (numberRange - 1)) + 1;
        num2 = Math.floor(Math.random() * (numberRange - num1)) + 1;
      } while (num1 + num2 > numberRange);
    } else {
      // op === "-"
      num1 = Math.floor(Math.random() * numberRange) + 1;
      num2 = Math.floor(Math.random() * num1); // Ensures result is positive
    }

    return {
      num1,
      num2,
      operator: op,
      result: op === "+" ? num1 + num2 : num1 - num2,
    };
  };

  // Generate all calculations for the game
  const generateAllCalculations = () => {
    const calcs = [];
    for (let i = 0; i < calculationCount; i++) {
      calcs.push(generateCalculation());
    }
    return calcs;
  };

  // Modified handleAnswerSelect to track answers
  const handleAnswerSelect = (answer) => {
    if (isAnimating) return;

    const currentCalc = calculations[currentIndex];
    const isCorrect = answer === currentCalc.result;

    setSelectedAnswer(answer);
    setIsAnswerCorrect(isCorrect);
    setIsAnimating(true);
    setProgress(0);

    // Update answers array
    const newAnswers = [...answers];
    newAnswers[currentIndex] = { answered: true, correct: isCorrect };
    setAnswers(newAnswers);

    setTimeout(() => {
      setIsAnimating(false);
      setSelectedAnswer(null);
      setIsAnswerCorrect(null);
      if (currentIndex < calculations.length - 1) {
        setCurrentIndex((prev) => prev + 1);
        setProgress(0);
      } else {
        // Game finished with answers, show score
        setShowScore(true);
        setIsPlaying(false);
      }
    }, 2000);
  };

  // Start the game
  const startGame = () => {
    const calcs = generateAllCalculations();
    setCalculations(calcs);
    setAnswers(
      new Array(calcs.length).fill({ answered: false, correct: false })
    );
    setCurrentIndex(0);
    setProgress(0);
    setSelectedAnswer(null);
    setIsAnswerCorrect(null);
    setIsAnimating(false);
    setIsPlaying(true);
  };

  // Modified useEffect to handle automatic advancement and score
  let timer;
  let progressTimer;
  useEffect(() => {
    if (isPlaying && calculations.length > 0 && !isAnimating) {
      timer = setInterval(() => {
        setCurrentIndex((prev) => {
          if (prev >= calculations.length - 1) {
            if (withAnswers) {
              setShowScore(true);
            }
            setIsPlaying(false);
            return prev;
          }
          // Mark current calculation as unanswered if we're moving to next
          const newAnswers = [...answers];
          if (!newAnswers[prev].answered) {
            newAnswers[prev] = { answered: false, correct: false };
            setAnswers(newAnswers);
          }
          setProgress(0);
          return prev + 1;
        });
      }, displayTime * 1000);

      // Progress bar timer
      const progressInterval = 50; // Update every 50ms for smooth animation
      progressTimer = setInterval(() => {
        setProgress((prev) => {
          const newProgress = prev + progressInterval / (displayTime * 1000);
          return Math.min(newProgress, 1);
        });
      }, progressInterval);
    }

    return () => {
      clearInterval(timer);
      clearInterval(progressTimer);
    };
  }, [isPlaying, calculations, displayTime, isAnimating, withAnswers, answers]);

  // Modified stopGame to not show score when manually stopped
  const stopGame = () => {
    setIsPlaying(false);
    setProgress(0);
    setCurrentIndex(0);
    setSelectedAnswer(null);
    setIsAnswerCorrect(null);
    setIsAnimating(false);
    setShowScore(false);
    setGameResults(null);
    clearInterval(timer);
    clearInterval(progressTimer);
  };

  // Reset game states
  const resetGame = () => {
    setShowScore(false);
    setGameResults(null);
    setCurrentIndex(0);
    setProgress(0);
    setSelectedAnswer(null);
    setIsAnswerCorrect(null);
    setIsAnimating(false);
    setIsPlaying(false);
  };

  return (
    <StyledGame>
      {!isPlaying && !showScore ? (
        <SettingsContainer>
          <h2>Blitzrechnen</h2>

          <RangeContainer>
            <RangeButton
              selected={operator === "+"}
              onClick={() => setOperator("+")}
            >
              nur +
            </RangeButton>
            <RangeButton
              selected={operator === "-"}
              onClick={() => setOperator("-")}
            >
              nur -
            </RangeButton>
            <RangeButton
              selected={operator === "+ & -"}
              onClick={() => setOperator("+ & -")}
            >
              + und -
            </RangeButton>
          </RangeContainer>

          <RangeContainer>
            <RangeButton
              selected={numberRange === 10}
              onClick={() => setNumberRange(10)}
            >
              Bis 10
            </RangeButton>
            <RangeButton
              selected={numberRange === 20}
              onClick={() => setNumberRange(20)}
            >
              Bis 20
            </RangeButton>
          </RangeContainer>

          <RangeContainer>
            <RangeButton
              selected={calculationCount === 10}
              onClick={() => setCalculationCount(10)}
            >
              10 Rechnungen
            </RangeButton>
            <RangeButton
              selected={calculationCount === 20}
              onClick={() => setCalculationCount(20)}
            >
              20 Rechnungen
            </RangeButton>
          </RangeContainer>

          <ToggleContainer>
            <label>
              <Toggle
                type='checkbox'
                checked={withAnswers}
                onChange={() => setWithAnswers(!withAnswers)}
              />
              <span>Mit Antworten</span>
            </label>
          </ToggleContainer>

          <SliderContainer>
            <label>
              <span>Anzeigezeit: {displayTime} Sekunden</span>
              <input
                type='range'
                min='1'
                max='10'
                value={displayTime}
                onChange={(e) => setDisplayTime(Number(e.target.value))}
              />
            </label>
          </SliderContainer>

          <StartButton onClick={startGame}>Start</StartButton>
        </SettingsContainer>
      ) : showScore ? (
        <ScoreContainer>
          <ScoreText>Rechnungen: {calculations.length}</ScoreText>
          <ScoreText correct>
            Richtig: {answers.filter((a) => a.answered && a.correct).length}
          </ScoreText>
          <ScoreText wrong>
            Falsch: {answers.filter((a) => a.answered && !a.correct).length}
          </ScoreText>
          <ScoreText unanswered>
            Nicht beantwortet: {answers.filter((a) => !a.answered).length}
          </ScoreText>
          <ScoreButton onClick={resetGame}>OK</ScoreButton>
        </ScoreContainer>
      ) : (
        <GameContainer>
          <TopBar>
            <Counter>
              {currentIndex + 1}/{calculations.length}
            </Counter>
            <CloseButton onClick={stopGame}>✕</CloseButton>
          </TopBar>

          <CalculationDisplay
            animate={
              isAnimating
                ? isAnswerCorrect
                  ? "correct"
                  : isAnswerCorrect === false
                  ? "wrong"
                  : "idle"
                : "idle"
            }
            variants={{
              idle: { scale: 1, x: 0, color: "#000000" },
              correct: {
                scale: [1, 1.1, 1, 1.1, 1, 1.1, 1],
                color: "#2d5a27",
                transition: {
                  duration: 2,
                  times: [0, 0.2, 0.4, 0.6, 0.8, 0.9, 1],
                },
              },
              wrong: {
                x: [-10, 10, -10, 10, -10, 10, -10, 10, -10, 10, 0],
                color: "#8b0000",
                transition: { duration: 2 },
              },
            }}
          >
            {calculations[currentIndex]?.num1}{" "}
            {calculations[currentIndex]?.operator}{" "}
            {calculations[currentIndex]?.num2} ={" "}
            {selectedAnswer !== null && (
              <span style={{ color: isAnswerCorrect ? "#2d5a27" : "#8b0000" }}>
                {selectedAnswer}
              </span>
            )}
          </CalculationDisplay>

          {withAnswers && !isAnimating && (
            <AnswerButtonContainer>
              {Array.from({ length: numberRange + 1 }, (_, i) => i).map(
                (num) => (
                  <AnswerButton
                    key={num}
                    onClick={() => handleAnswerSelect(num)}
                  >
                    {num}
                  </AnswerButton>
                )
              )}
            </AnswerButtonContainer>
          )}

          <ProgressBarContainer>
            <ProgressBar progress={progress} />
          </ProgressBarContainer>
        </GameContainer>
      )}
    </StyledGame>
  );
};

const StyledGame = styled(motion.div)`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: white;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 600px;

  h2 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
`;

const RangeContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const RangeButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: 2px solid #4a90e2;
  border-radius: 0.5rem;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: ${(props) => (props.selected ? "3px solid #4a90e2" : "none")};

  &:hover {
    background: #f0f0f0;
  }
`;

const ToggleContainer = styled.div`
  label {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
  }
`;

const Toggle = styled.input.attrs({ type: "checkbox" })`
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
`;

const SliderContainer = styled.div`
  width: 100%;
  max-width: 400px;

  label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 1.2rem;
  }

  input[type="range"] {
    width: 100%;
    height: 8px;
    -webkit-appearance: none;
    background: #f0f0f0;
    border-radius: 4px;
    outline: none;
    margin: 10px 0;
    position: relative;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: #4a90e2;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-top: -6px;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px rgba(74, 144, 226, 0.3);
      }
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: #4a90e2;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      transform: translateY(-6px);

      &:hover {
        transform: scale(1.1) translateY(-6px);
        box-shadow: 0 0 10px rgba(74, 144, 226, 0.3);
      }
    }

    &::-webkit-slider-runnable-track {
      background: #4a90e2;
      height: 8px;
      border-radius: 4px;
    }

    &::-moz-range-track {
      background: #4a90e2;
      height: 8px;
      border-radius: 4px;
    }
  }
`;

const StartButton = styled.button`
  padding: 1rem 3rem;
  font-size: 1.5rem;
  background: #4a90e2;
  border: none;
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const GameContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
`;

const TopBar = styled.div`
  position: fixed;
  top: 3.5rem;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem;
  gap: 1rem;
  z-index: 100;
  pointer-events: all;
`;

const Counter = styled.div`
  font-size: 2rem;
  color: #666;
  user-select: none;
`;

const CloseButton = styled.button`
  position: relative;
  background: none;
  border: none;
  font-size: 2rem;
  color: #666;
  padding: 0.8rem;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 100;
  border-radius: 50%;
  cursor: pointer !important;
  pointer-events: all;
  background: rgba(255, 255, 255, 0.9);

  &:hover {
    color: #333;
    transform: scale(1.1);
    background: rgba(0, 0, 0, 0.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const CalculationDisplay = styled(motion.div)`
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 25vh;
  white-space: nowrap;
  pointer-events: none;
  user-select: none;

  span {
    display: inline-block;
  }

  @media (max-width: 768px) {
    font-size: 15vh;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  background: #f0f0f0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.progress * 100}%;
  height: 100%;
  background: #4a90e2;
  transition: width 0.05s linear;
`;

const AnswerButtonContainer = styled.div`
  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  justify-content: center;
  padding: 1.5rem;
  width: 80vw;
  max-width: 1000px;
`;

const AnswerButton = styled.button`
  width: 3.5rem;
  height: 3.5rem;
  border: 2px solid #4a90e2;
  border-radius: 50%;
  background: white;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #333;

  &:hover {
    transform: scale(1.1);
    background: #f0f0f0;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
`;

const ScoreText = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${(props) => {
    if (props.correct) return "#2d5a27";
    if (props.wrong) return "#8b0000";
    if (props.unanswered) return "#666666";
    return "#000000";
  }};
`;

const ScoreButton = styled.button`
  margin-top: 2rem;
  padding: 1rem 3rem;
  font-size: 1.5rem;
  border: 2px solid #4a90e2;
  border-radius: 0.5rem;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #f0f0f0;
    transform: scale(1.05);
  }
`;

export default Blitzrechnen;
